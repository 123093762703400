import { FlexBox } from "@braze/beacon-core";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const PageNotFoundContainer = styled(FlexBox)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  min-height: calc(100vh - 104.5px - 32.5px);
`;

export const StyledLink = styled(Link)`
  position: absolute;
  z-index: 10;
  left: 52%;
  top: 58%;
  background-color: black;
  color: white;
  padding: 0.5rem;
  text-decoration: none;
  cursor: pointer;
  font-size: medium;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  vertical-align: baseline;
`;
